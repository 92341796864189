import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/Main.scss";
import "../styles/componentsScss/clc.scss";
import { useTranslation } from 'react-i18next';
import DefaultHeader from "../components/DefaultHeader";
import lMODELbG from '../pages/Imgs/LmodelBG.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import clccard from "../pages/Imgs/clcCard.png"
import { BASE_URL } from "../components/apiConfig";
import axios from "axios";


function CLC() {
    const [t, i18n] = useTranslation();
    const url = window.location.href.split("/");
    const urlPage = url[3];

    const [clc, setClc] = useState([])
    const [clcFcit, setFacIt] = useState([])
    const [clcFcbusiness, setFacBusiness] = useState([])
    const [clcCourses, setclcCourses] = useState([])
    const [clcCourses1, setclcCourses1] = useState([])
    const [clcPartner, setclcPartner] = useState([])

    const [selectedProgram, setSelectedProgram] = useState(null);

    const handleButtonClick = (programName, _id) => {
        setSelectedProgram(selectedProgram === programName ? null : programName);

        axios.get(`${BASE_URL}/program/${_id}`)
            .then((res) => {
                setclcCourses(res.data.data)
                // console.log(res.data.data)
            })
    };

    const [showCards2, setShowCards2] = useState(false)
    const handleClick2 = () => {
        setShowCards2(!showCards2);
    };
    const [showCards3, setShowCards3] = useState(false)

    const handleClick3 = (_id) => {
        setShowCards3(!showCards3);
        axios.get(`${BASE_URL}/program/${_id}`)
            .then((res) => {
                setclcCourses1(res.data.data)
                // console.log(res.data.data)
            })

    };

    const [showCards4, setShowCards4] = useState(false)
    const handleClick4 = () => {
        setShowCards4(!showCards4);
    };
    const [showCards5, setShowCards5] = useState(false)
    const handleClick5 = () => {
        setShowCards5(!showCards5);
    };

    useEffect(() => {
        axios.get(`${BASE_URL}/program/non-undergraduate`)
            .then((res) => {
                setClc(res.data.data)
                // console.log(res.data.data)
            })
    }, [])

    useEffect(() => {
        axios.get(`${BASE_URL}/program/undergraduate/it`)
            .then((res) => {
                setFacIt(res.data.data)
                // console.log(res.data.data)
            })
    }, [])

    useEffect(() => {
        axios.get(`${BASE_URL}/program/undergraduate/business`)
            .then((res) => {
                setFacBusiness(res.data.data)
                // console.log(res.data.data)
            })
    }, [])


    useEffect(() => {
        axios.get(`${BASE_URL}/clcPartner`)
            .then((res) => {
                setclcPartner(res.data)
                // console.log(res.data)
            })
    }, [])

    return (

        <Fragment>
            <DefaultHeader title={t("Continuing Learning Center")}
                backgroundImage={lMODELbG} />
            <div className="BodyDiv">

                <Container className="MainPageBodyContainer">

                    <div className="BodyTitle">
                        <Row>
                            <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} >
                            </Col>
                            <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} >
                                <div style={{ color: '#09154E' }}>
                                    <svg width="50" height="35" viewBox="0 0 215 259" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3480_4927)">
                                            <path d="M124.566 163.976C124.158 162.903 122.956 162.362 121.882 162.77C120.809 163.177 120.268 164.38 120.675 165.453L154.662 254.839H62.3182L77.8801 213.907H121.716C122.864 213.907 123.796 212.975 123.796 211.827C123.796 210.679 122.864 209.747 121.716 209.747H79.4613L85.5363 193.766H110.718C111.867 193.766 112.799 192.834 112.799 191.686C112.799 190.537 111.867 189.605 110.718 189.605H87.1216L96.3048 165.453C96.7126 164.38 96.1716 163.177 95.0981 162.77C94.0246 162.362 92.8221 162.903 92.4143 163.976L57.3542 256.183C57.1129 256.824 57.1961 257.539 57.5872 258.105C57.9742 258.667 58.615 259.004 59.3015 259.004H157.679C158.361 259.004 159.002 258.667 159.393 258.105C159.78 257.543 159.867 256.824 159.626 256.183L124.566 163.976Z" fill="#061041" />
                                            <path d="M212.92 142.45H115.304C114.022 139.5 112.445 137.07 110.619 135.073C110.606 135.061 112.82 133.759 113.032 133.655C113.91 133.218 114.817 132.843 115.741 132.523C117.742 131.828 119.827 131.383 121.924 131.083C127.025 130.355 132.222 130.422 137.357 130.588C143.569 130.788 149.777 131.179 155.985 131.47C162.193 131.761 168.243 131.99 174.381 132.061C180.502 132.128 186.627 132.04 192.735 131.641C196.059 131.424 199.376 131.108 202.679 130.676C203.82 130.526 204.618 129.477 204.469 128.337C204.319 127.197 203.266 126.394 202.13 126.548C196.259 127.33 190.334 127.692 184.413 127.842C178.263 127.996 172.109 127.909 165.959 127.709C159.809 127.509 154.021 127.214 148.059 126.91C145.034 126.91 141.988 126.594 138.963 126.469C135.938 126.344 133.2 126.274 130.321 126.315C125.086 126.39 119.748 126.827 114.755 128.508C112.162 129.377 109.687 130.601 107.498 132.244C107.456 132.273 104.24 130.193 103.895 130.018C102.501 129.315 101.04 128.745 99.5505 128.283C96.4589 127.322 93.2384 126.823 90.0136 126.557C81.4879 125.858 72.9205 126.611 64.3989 127.039C55.8773 127.468 48.1296 127.826 39.9866 127.905C30.9366 127.988 21.8491 127.738 12.8698 126.548C11.7297 126.399 10.6853 127.197 10.5313 128.337C10.3774 129.477 11.1804 130.522 12.3205 130.676C28.4026 132.814 44.7676 132.165 60.9203 131.383C68.822 131 76.7486 130.393 84.6668 130.505C90.463 130.588 96.617 131.017 101.901 133.63C102.118 133.738 104.39 135.065 104.377 135.082C102.551 137.079 100.974 139.508 99.692 142.458H2.08047C0.932051 142.458 0 143.39 0 144.539C0 145.687 0.932051 146.619 2.08047 146.619H101.094C101.956 146.619 102.725 146.091 103.037 145.283C104.215 142.217 105.709 139.766 107.502 137.807C109.3 139.771 110.789 142.221 111.967 145.283C112.275 146.086 113.049 146.619 113.91 146.619H212.924C214.072 146.619 215.004 145.687 215.004 144.539C215.004 143.39 214.072 142.458 212.924 142.458L212.92 142.45Z" fill="#061041" />
                                            <path d="M84.24 114.387C84.9391 115.061 85.7796 115.535 86.6451 115.219C87.5646 114.878 87.9599 113.725 87.7685 112.76C87.5771 111.795 86.9571 110.988 86.358 110.21C84.2317 107.464 82.1554 104.676 80.129 101.855C74.5742 94.1165 69.2981 85.862 67.3175 76.5464C64.151 61.664 70.4923 45.2589 82.6797 36.1472C94.8671 27.0356 112.21 25.5336 125.908 32.1614C133.734 35.9475 140.367 42.2092 144.457 49.8855C148.543 57.5576 150.041 66.611 148.485 75.1651C146.754 84.6679 141.486 93.1222 135.902 101.006C133.734 104.069 131.504 107.089 129.211 110.06C128.379 111.142 127.489 112.394 127.718 113.738C127.888 114.741 129.033 115.406 129.998 115.227C131.101 115.019 131.7 113.754 132.332 112.927C134.321 110.33 136.273 107.709 138.182 105.055C144.291 96.563 150.099 87.5096 152.271 77.2787C155.584 61.7057 149.442 44.5849 137.196 34.4081C124.951 24.2313 107.196 21.294 92.2082 26.6611C81.8267 30.3807 72.8141 37.903 67.5047 47.568C62.1954 57.233 60.6933 68.97 63.6143 79.6003C65.7322 87.3098 70.0138 94.2289 74.4826 100.857C77.3578 105.117 80.3329 109.307 83.4078 113.426C83.6533 113.75 83.9321 114.091 84.2442 114.387H84.24Z" fill="#061041" />
                                            <path d="M108.489 51.2211C115.954 51.2211 122.025 57.2914 122.025 64.7555C122.025 65.9038 122.957 66.8358 124.105 66.8358C125.254 66.8358 126.186 65.9038 126.186 64.7555C126.186 54.9989 118.247 47.0605 108.489 47.0605C98.7321 47.0605 90.793 54.9989 90.793 64.7555C90.793 74.512 98.7321 82.4504 108.489 82.4504C109.638 82.4504 110.57 81.5184 110.57 80.3701C110.57 79.2218 109.638 78.2898 108.489 78.2898C101.025 78.2898 94.9539 72.2195 94.9539 64.7555C94.9539 57.2914 101.025 51.2211 108.489 51.2211Z" fill="#061041" />
                                            <path d="M139.834 18.5357C149.683 22.9126 158.625 33.78 160.631 43.8112C160.83 44.8014 161.696 45.4837 162.669 45.4837C162.807 45.4837 162.944 45.4713 163.081 45.4421C164.209 45.2175 164.937 44.1191 164.712 42.9957C162.428 31.5749 152.675 19.6882 141.528 14.7329C140.475 14.2669 139.247 14.7412 138.781 15.7897C138.315 16.8382 138.786 18.0697 139.838 18.5357H139.834Z" fill="#061041" />
                                            <path d="M145.824 4.07738C155.664 6.98978 176.248 29.8813 178.133 45.3544C178.262 46.4112 179.161 47.1851 180.197 47.1851C180.28 47.1851 180.368 47.1809 180.451 47.1685C181.591 47.0312 182.402 45.991 182.265 44.851C180.114 27.1852 158.09 3.36592 147.005 0.0873852C145.903 -0.23714 144.746 0.391107 144.421 1.49366C144.097 2.59621 144.725 3.75285 145.828 4.07738H145.824Z" fill="#061041" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3480_4927">
                                                <rect width="215" height="259" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    {t('Continuing Learning Center')}

                                </div>

                            </Col>

                        </Row>
                    </div>

                    <Row>
                        <Col className="BodyCol" >
                            <div className="BodyText">

                                <ul style={{ listStyleType: 'none' }}>


                                    <li className="facultyDesign">
                                        {t("About Continuing Learning Center (CLC)")}                                        </li>
                                    <p className="">{t("In February 2011, the University opened the Continuing Learning Center (CLC), which offers professional training courses to a wide variety of different Egyptian society sectors and provide continuous learning opportunities for various educational and training programs in different fields to cover all business needs through a set of tracks each track contains a set of training programs such as.")}</p>
                                    <ul style={{ listStyleType: 'disc', marginRight: '-1%' }}>
                                        <p style={{ fontSize: '20px' }}>
                                            <li>{t("Management Track")}</li>
                                            <li>{t("Information Technology Track")}</li>
                                            <li>{t("Entrepreneurship  Track")}</li>
                                            <li>{t("Languages Track")}</li>

                                        </p>

                                    </ul>

                                    <p className="">{t("CLC offers the training programs for companies, public and undergraduates. CLC offers all training programs, both virtual and physical depending on a practical method in the training through practical business cases. So, we hire trainers who have scientific and practical experience in their fields")}</p>
                                    <p className="Subheader">{t("Objectives")}</p>

                                    <ul style={{ listStyleType: 'disc', marginRight: '-1%' }}>
                                        <p style={{ fontSize: '20px' }}>
                                            <li>{t("To acts as a link between academic and practical experiences and shared them with the business market,")}</li>
                                            <li>{t("To offer flexible education and training opportunities distantly and physically using latest ICT technologies")}</li>
                                            <li>{t("To improve the workforce professional skills and competencies in different domains")}</li>
                                            <li>{t("To offer courses those are aligned with labor market needs and specifications")}</li>

                                        </p>

                                    </ul>
                                    <p className="Subheader">{t("Clients")}</p>
                                    <p className="">{t("The beneficiaries and the participants of the different degree and non-degree programs vary among programs according to the content of each program and the method of delivery whether focusing on technical issues or managerial aspects. Respectively, the beneficiaries of the program delivered by the center and/or with its partner institutions and alliances include:")}</p>

                                    <ul style={{ listStyleType: 'disc', marginRight: '-1%' }}>
                                        <p style={{ fontSize: '20px' }}>
                                            <li>{t("Top & Senior Executives")}</li>
                                            <li>{t("Middle Managers")}</li>
                                            <li>{t("Trainers, Instructors & Lecturers")}</li>
                                            <li>{t("Technical Support Teams")}</li>
                                            <li>{t("End-Users")}</li>

                                        </p>

                                    </ul>
                                </ul>


                            </div>


                        </Col>

                    </Row>


                </Container>
            </div>
            <Container>
                <h2 style={{
                    textAlign: 'center'
                    , color: '#09154E'
                }}>{t("Training Programs")}</h2>
                <br></br>

                <div className="flex-container">

                    <div >
                        {clc.map(clctraning1 => (
                            urlPage === 'en' ? (
                                clctraning1.programNameEn ? (
                                    <button
                                        onClick={() => handleButtonClick(clctraning1.programName, clctraning1._id)}
                                        className={`cardbutton ${selectedProgram === clctraning1.programName ? 'active' : ''}`}
                                    >
                                        {clctraning1.programNameEn}
                                    </button>
                                ) : null
                            ) : (
                                <button
                                    onClick={() => handleButtonClick(clctraning1.programName, clctraning1._id)}

                                    className={`cardbutton ${selectedProgram === clctraning1.programName ? 'active' : ''}`}
                                >
                                    {clctraning1.programName}
                                </button>
                            )


                        ))}
                        <br></br>


                        <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selectedProgram && (
                                clcCourses.map(clcCourcess1 => (
                                    <div key={clcCourcess1._id} className="row row-cols-1 row-cols-md-4 g-2">
                                        <div className="col">
                                            <div className="card" style={{ border: '2px solid #09154E', width: '90%', marginRight: '5%', borderRadius: '12.2px', height: '92%', marginTop: '10%' }}>
                                                <LazyLoadImage src={clccard} className="card-img-top" alt="..." style={{ width: '100%', height: '120px' }} />
                                                <div className="card-body">
                                                    <h4 className="card-title" style={{ textAlign: 'center', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', textOverflow: 'ellipsis', fontWeight: 700 }}>
                                                        {urlPage === 'en' ? clcCourcess1.courseNameEn.split(' ').slice(0, 3).join(' ') : clcCourcess1.courseName.split(' ').slice(0, 3).join(' ')}

                                                    </h4>
                                                    <p style={{ textAlign: 'center', color: '#F6A62E', marginBottom: '-3px' }}>{clcCourcess1.duration.hours}  ساعة</p>
                                                    <Link to={urlPage === 'en' ? `/en/clc/${clcCourcess1.params}` : `/clc/${clcCourcess1.params}`} style={{ textDecoration: 'none' }}>
                                                        <button type="submit" className="clcbutton">
                                                            {t('Show More')}
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )))}
                        </div>
                    </div>


                </div>

                {/* <div className="button-container">

                    {clc.map(clctraning1 => (

                        <div key={clctraning1.programName}>

                            {urlPage === 'en' ? (
                                clctraning1.programNameEn ? (
                                    <button
                                        onClick={() => handleButtonClick(clctraning1.programName, clctraning1._id)}
                                        className={`cardbutton ${selectedProgram === clctraning1.programName ? 'active' : ''}`}
                                    >
                                        {clctraning1.programNameEn}
                                    </button>
                                ) : null
                            ) : (
                                <button
                                    onClick={() => handleButtonClick(clctraning1.programName, clctraning1._id)}
                                    className={`cardbutton ${selectedProgram === clctraning1.programName ? 'active' : ''}`}
                                >
                                    {clctraning1.programName}
                                </button>
                            )}

                            <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div className="">

                                    {selectedProgram === clctraning1.programName && (
                                        clcCourses.map(clcCourcess => (
                                            <div className="col" key={clcCourcess._id}>
                                                <div className="card" style={{ border: '2px solid #09154E', marginRight: '5%', borderRadius: '12.2px', marginBottom: '5%' }}>
                                                    <LazyLoadImage src={clccard} className="card-img-top" alt="..." style={{ width: '100%', height: '120px' }} />
                                                    <div className="card-body">
                                                        <h1 className="card-title"
                                                            style={{
                                                                textAlign: 'center',
                                                                height: '2.4em',
                                                                fontWeight: 700
                                                            }}>
                                                            {urlPage === 'en' ? clcCourcess.courseNameEn.split(' ').slice(0, 4).join(' ') : clcCourcess.courseName.split(' ').slice(0, 4).join(' ')}
                                                        </h1>
                                                        <p style={{ textAlign: 'center', color: '#F6A62E', marginBottom: '-3px', marginTop: '-5%' }}>{clcCourcess.duration.hours} {t("Hours")}</p>
                                                        <Link to={urlPage === 'en' ? `/en/clc/${clcCourcess.params}` : `/clc/${clcCourcess.params}`} style={{ textDecoration: 'none' }} >
                                                            <button type="submit" className="clcbutton">
                                                                {t('Show More')}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>

                    ))}
                </div> */}
                <br></br>
                <h2 style={{
                    textAlign: 'center'
                    , color: '#09154E'
                }} >{t("Student Training Programs")}</h2>
                <br></br>

                <div className="flex-container">
                    <div>
                        {

                            <button
                                onClick={handleClick2}
                                className={`cardbutton ${showCards2 ? 'active' : ''}`}
                            >
                                {t("Computers and Information Technology")}
                                {/* {showCards2 ? 'حاسبات وتكنولوجيا المعلومات' : 'حاسبات وتكنولوجيا المعلومات'} */}
                            </button>

                        }

                        <br></br>
                        <br></br>

                        {clcFcit.map((clctraningfci, index, _id) => (
                            showCards2 && (
                                <button
                                    key={index}
                                    className={`cardbutton2 ${showCards3 ? 'active' : ''}`}
                                    onClick={() => handleClick3(clctraningfci._id)}

                                >
                                    {showCards3 ? clctraningfci.programName : clctraningfci.programName}
                                </button>
                            )
                        ))}
                        <br></br>
                        <br></br>

                        <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {showCards3 && (
                                clcCourses1.map(clcCourcess1 => (
                                    <div key={clcCourcess1._id} className="row row-cols-1 row-cols-md-4 g-2">
                                        <div className="col">
                                            <div className="card" style={{ border: '2px solid #09154E', width: '90%', marginRight: '5%', borderRadius: '12.2px', height: '92%' }}>
                                                <LazyLoadImage src={clccard} className="card-img-top" alt="..." style={{ width: '100%', height: '120px' }} />
                                                <div className="card-body">
                                                    <h4 className="card-title" style={{ textAlign: 'center', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', textOverflow: 'ellipsis', fontWeight: 700 }}>{clcCourcess1.courseName.split(' ').slice(0, 3).join(' ')}</h4>
                                                    <p style={{ textAlign: 'center', color: '#F6A62E', marginBottom: '-3px' }}>{clcCourcess1.duration.hours}  ساعة</p>
                                                    <Link to={urlPage === 'en' ? `/en/clc/${clcCourcess1.params}` : `/clc/${clcCourcess1.params}`} style={{ textDecoration: 'none' }}>
                                                        <button type="submit" className="clcbutton">
                                                            {t('Show More')}
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )))}
                        </div>
                    </div>

                    <div className="businesscardCourse">
                        <button
                            onClick={handleClick4}
                            className={`cardbutton ${showCards4 ? 'active' : ''}`}
                        >
                            {t("Business Administration")}
                            {/* {showCards4 ? 'إدارة أعمال' : 'إدارة أعمال '} */}
                        </button>
                        <br></br>

                        {clcFcbusiness.map((clctraningbusiness, index) => (
                            showCards4 && (
                                <button
                                    key={index}
                                    className={`cardbutton2 ${showCards5 ? 'active' : ''}`}
                                    onClick={handleClick5}
                                >
                                    {showCards5 ? clctraningbusiness.programName : clctraningbusiness.programName}
                                </button>
                            )
                        ))}
                        {showCards5 && (
                            <div  >
                                <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>


                                    <div className="row row-cols-1 row-cols-md-4 g-2">
                                        <div className="col">
                                            <div className="card" style={{ border: '2px solid #09154E', width: '90%', marginRight: '5%', borderRadius: '12.2px', height: '92%' }}>
                                                <LazyLoadImage src={clccard} className="card-img-top" alt="..." style={{ width: '100%', height: '120px' }} />
                                                <div className="card-body">
                                                    <h4 className="card-title" style={{ textAlign: 'center', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2', textOverflow: 'ellipsis', fontWeight: 700 }}>اداره التدريب المتقدمة</h4>
                                                    <p style={{ textAlign: 'center', color: '#F6A62E', marginBottom: '-3px' }}>132 ساعة</p>
                                                    <Link to="/clc-course" style={{ textDecoration: 'none' }}>
                                                        <button type="submit" className="clcbutton">
                                                            {t('Show More')}
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        )}
                    </div>
                </div>

            </Container>
            <Container className="MainPageBodyContainer">


                <Row>
                    <Col className="BodyCol" >
                        <div className="BodyText">
                            <ul>
                                <li className="facultyDesign" style={{ listStyleType: 'none' }}>
                                    {t("Quality of Training")}
                                </li>
                                <ul style={{ listStyleType: 'none' }}>

                                    <p className="Subheader">{t("Quality of Training Methodology")}</p>
                                    <p className="" style={{ color: '#142B7B' }}>{t("At EELU, our commitment to ensuring that all courses are practical, highly interactive, and action oriented, whether it is virtual or physical.")}</p>


                                    <p className="Subheader">{t("Quality of Trainers")}</p>
                                    <p className="" style={{ color: '#142B7B' }}>{t("We hire best quality of trainers in Egypt and the region wit h the following specifications:")}</p>


                                    <p className="Subheader">{t("Trainers’ Certification:")}</p>
                                    <p className="" style={{ color: '#142B7B' }}>{t("All trainers have to be certified as a professional trainer from one of the international most renowned certification agencies in the field of professional training.")}</p>


                                    <p className="Subheader">{t("Trainer experience:")}</p>
                                    <p className="" style={{ color: '#142B7B' }}>{t("Our entire trainer should have experience in training with national and international organizations. Also the trainer must have practical experience in the field he is teaching.")}</p>


                                    <p className="Subheader">{t("High ratings in trainee’s evaluation of training:")}</p>
                                    <p className="" style={{ color: '#142B7B' }}>{t("All our trainers should have a proven record of high scores of trainees’ rating and levels of satisfaction with reference letters that proves it.")}</p>


                                    <p className="Subheader">{t("Quality of Training Materials")}</p>
                                    <p className="" style={{ color: '#142B7B' }}>{t("We care about providing complete set of training materials covered the all outlines of training will be provided to trainees (whether hard or digital) to follow the trainer during training and to be a reference to them after training.")}</p>


                                    <p className="Subheader">{t("Quality of Program's Logistics")}</p>
                                    <p className="" style={{ color: '#142B7B' }}>{t("Participants who attended at least 75% of the program’s duration and passed all training requirements such as participating in practical training groups or preparing projects or any other requirements. They will be granted an individual certificate of attendance from EELU.")}</p>


                                    <p className="Subheader">{t("Quality of Facilities")}</p>
                                    <p className="" style={{ color: '#142B7B' }}>{t("Currently EELU and by extension CLC owns 13 branches in Egypt equipped with all training facilities whether for virtual or physical.")}</p>



                                </ul>
                            </ul>

                        </div>


                    </Col>

                </Row>


            </Container>
            <br></br>
            <br></br>
            <Container className="MainPageBodyContainer">


                <Row>
                    <Col className="BodyCol" >
                        <div className="BodyText">
                            <ul style={{ listStyleType: 'none' }}>

                                <p className="Subheader"> {t("Partners")}</p>
                                <p className="" style={{ color: '#142B7B' }}>

                                    {t("We work with a team working at the highest levels of professionalism and expertise to provide training content that is professional and identical to quality standards and covers the gap between academic studies and what the labor market needs. In this framework, several agreements have been concluded with international companies to provide specialized certificates in many disciplines through the CLC such as.")}
                                </p>
                                {
                                    clcPartner.map(partnerclc => {
                                        return <> <h2 style={{
                                            textAlign: 'center'
                                            , color: '#09154E'
                                        }}>
                                            {urlPage === 'en' ? partnerclc.nameEn : partnerclc.name}
                                        </h2>
                                            <br></br>
                                            <Container style={{}}>
                                                <Row>
                                                    {partnerclc.logos.map((logo, index) => (
                                                        <Col key={index} xs={6} md={4} lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <div className="shortIconText">
                                                                <LazyLoadImage src={`${BASE_URL}/${logo}`} alt="" style={{ height: '50px', }} />
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Container>
                                        </>

                                    })
                                }

                            </ul>


                        </div>


                    </Col>

                </Row>
                {

                }


            </Container >
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

        </Fragment >
    );
}
export default CLC
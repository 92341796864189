import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/componentsScss/Breadcrumbs.scss";

function Breadcrumbs() {
  const url = window.location.href.split("/");
  const urlPage = url[3];

  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split("/").filter((segment) => segment !== ""); // Remove empty segments

  // Check if current page is home
  const isHome = pathname === "/";

  const breadcrumbsLink = segments.map((segment, i) => {
    // Decode the URL-encoded segment
    const decodedSegment = decodeURIComponent(segment);
    const urll = `${segments.slice(0, i + 1).join("/")}`; // Construct URL for current segment

    // Capitalize and format segment for display
    const capitalizedSegment = decodedSegment
      .replace(/-/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    const displaySegment =
      capitalizedSegment.length > 50
        ? `...${capitalizedSegment.substring(0, 50)}`
        : capitalizedSegment;

    const isLastSegment = i === segments.length - 1; // Check if it's the last segment

    return (
      <Fragment key={i}>
        <li
          className=""
          style={{ listStyleType: "none", display: "inline-block" }}
        >
          {!isLastSegment ? (
            <>
              <Link to={urll} className="breadcrumb-item">
                {displaySegment}
              </Link>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: urlPage === "en" ? "none" : "scaleX(-1)" }}
              >
                <path
                  d="M8.90625 19.92L15.4263 13.4C16.1963 12.63 16.1963 11.37 15.4263 10.6L8.90625 4.08002"
                  stroke="#0775F2"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          ) : (
            <span className="spanbreadcrumb">{displaySegment}</span>
          )}
        </li>
      </Fragment>
    );
  });

  // Render breadcrumbs in the same line if not home
  return (
    !isHome && (
      <ol
        className={
          urlPage === "en" ? "breadcrumbsreturnEn" : "breadcrumbsreturn"
        }
      >
        {/* Conditionally render "Home" link */}
        {urlPage !== "en" && (
          <>
            <li className="breadcrumb-item">
              <Link className="breadcrumb-item" to="/">
                Home
              </Link>
            </li>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ transform: urlPage === "en" ? "none" : "scaleX(-1)" }}
            >
              <path
                d="M8.90625 19.92L15.4263 13.4C16.1963 12.63 16.1963 11.37 15.4263 10.6L8.90625 4.08002"
                stroke="#0775F2"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>
        )}
        {/* Render other breadcrumbs */}
        {breadcrumbsLink}
      </ol>
    )
  );
}

export default Breadcrumbs;
